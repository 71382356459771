import {createButton} from "react-social-login-buttons";
 
const config = {
  icon: 'apple',
  iconFormat: name => `fa fa-${name}`,
  style: { background: "white" },
  activeStyle: { background: "white", opacity:'0.9' }
};
const AppleButton = createButton(config);
 
export default AppleButton;