import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";

const { Header } = Layout;

const PageHeader = ({ selected }) => {
  return (
    <Header className="App-header">
      <Link to="/">
        <img
          src={process.env.PUBLIC_URL + "/logo.svg"}
          className="App-logo"
          alt="logo"
        />
      </Link>
    </Header>
  );
};

export default PageHeader;
