import LocalizedStrings from "react-localization"
import en from "./languages/en"
import pt from "./languages/pt_BR"
import ar from "./languages/ar"
import de from "./languages/de"
import it from "./languages/it"
import es from "./languages/es"
import fr from "./languages/fr"
import hi_IN from "./languages/hi_IN"
import ja from "./languages/ja"
import ko from "./languages/ko"
import ru from "./languages/ru"
import tr from "./languages/tr"
import zh_CN from "./languages/zh_CN"
import zh_TW from "./languages/zh_TW"
import id from "./languages/id"
import ms from "./languages/ms"
import nl from "./languages/nl"
import pl from "./languages/pl"
import sv from "./languages/sv"
import th from "./languages/th"
import vi from "./languages/vi"

let strings = new LocalizedStrings({
  en,
  pt,
  ar,
  de,
  es,
  fr,
  hi: hi_IN,
  it,
  ja,
  ko,
  ru,
  tr,
  zh: zh_CN,
  "zh-CN": zh_CN,
  "zh-TW": zh_TW,
  id,
  ms,
  nl,
  pl,
  sv,
  th,
  vi,
})

export default strings
