import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { isIOS, isAndroid } from "react-device-detect";
// import './config/sentry'
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/index.css";
import "./css/App.css";
import Login from "./Login";
import DiscoverMobile from "./DiscoverMobile";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Switch, Route } from "react-router-dom";

const LazyApp = lazy(() => import("./AppContext"));
const LoadableApp = ({ location }) => (
  <Suspense fallback={<div>Loading...</div>}>
    <LazyApp location={location} />
  </Suspense>
);

try {
  if (window.location.search.indexOf("beta=1") === 1) {
    localStorage.setItem("moises-beta", "yup");
  }

  if (window.location.search.indexOf("beta=0") === 1) {
    localStorage.removeItem("moises-beta");
  }
} catch (err) {
  // Its fine!
}

const Terms = () => {
  window.location.replace(
    "https://help.moises.ai/hc/en-us/articles/360013805260-Moises-Terms-and-Conditions-of-Use"
  );
  return null;
};

const Privacy = () => {
  window.location.replace(
    "https://help.moises.ai/hc/en-us/articles/360013805640-Privacy-Policy"
  );
  return null;
};

const Cookies = () => {
  window.location.replace(
    "https://help.moises.ai/hc/en-us/articles/360013807280-Cookies-Policy"
  );
  return null;
};

const Support = () => {
  window.location.replace("https://help.moises.ai/hc/");
  return null;
};

const iOSApp = () => {
  if (isIOS) {
    window.location.replace("https://moisesapp.page.link/launchApp");
  } else {
    window.location.replace("https://apps.apple.com/app/id1515796612");
  }
  return null;
};

const AndroidApp = ({ location: { search } }) => {
  if (isAndroid) {
    window.location.replace("https://moisesapp.page.link/launchApp");
  } else {
    window.location.replace(
      `https://play.google.com/store/apps/details${
        search ? `${search}&id=ai.moises` : `?id=ai.moises`
      }`
    );
  }
  return null;
};

const StudioRedirect = ({ location: { search } }) => {
  // screen=pricingPage
  // if (search.includes("screen=pricingPage"))

  window.location.replace(`https://studio.moises.ai/login/`);

  return null;
};

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route path="/login" component={Login} /> */}
      <Route exact path="/" component={StudioRedirect} />
      <Route path="/login" component={StudioRedirect} />
      <Route path="/discover-mobile" component={DiscoverMobile} />
      <Route path="/ios" component={iOSApp} />
      <Route path="/android" component={AndroidApp} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/privacy/" component={Privacy} />
      <Route path="/support" component={Support} />
      <Route path="/cookies" component={Cookies} />
      <Route path="/terms" component={Terms} />
      <Route path="/app" component={StudioRedirect} />
      <Route path="/api" component={StudioRedirect} />
      <Route path="/tasks" component={StudioRedirect} />
      <Route path="/mixer" component={StudioRedirect} />
      <Route path="/mixer" component={StudioRedirect} />
      <Route path="/pricing" component={StudioRedirect} />
      <Route path="/subscribe" component={StudioRedirect} />
      <Route path="/subscribe-br" component={StudioRedirect} />
      <Route path="/settings" component={StudioRedirect} />
      <Route path="/add-sub" component={StudioRedirect} />
      <Route path="/admin" component={StudioRedirect} />
      <Route path="/change-email" component={StudioRedirect} />
      <Route path="/discover" component={StudioRedirect} />
      <Route component={LoadableApp} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
