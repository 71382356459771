let env = "production";
if (window && window.location) {
  if (window.location.href.includes("dev.moises.ai")) {
    env = "dev";
  } else if (window.location.href.includes("app-stage.moises.ai")) {
    env = "stage";
  }
}

const configs = {
  dev: {
    env: "dev",
    getAudioMix: {
      endpoint: "https://dev.moises.ai:8002/v1/run",
    },
    urlDownload: {
      endpoint: "https://url-download-stage.moises.ai",
    },
    api: {
      endpoint: "http://api-dev.moises.ai:8000",
    },
    serviceBillingApi: {
      endpoint: "http://billing.dev.moises.ai:8001",
    },
    firebase: {
      auth: {
        apiKey: "AIzaSyClKhEICctB11XUWd0GyO50Zu7aVWpwp-M",
        authDomain: "moises-stage.firebaseapp.com",
        databaseURL: "https://moises-stage.firebaseio.com",
        projectId: "moises-stage",
        storageBucket: "moises-stage.appspot.com",
        messagingSenderId: "941298360423",
        appId: "1:941298360423:web:eae58942fc585849a8f4c2",
      },
    },
    stripe: {
      apiKey: "pk_test_mRYBN1HrxY09g6KUL0jx9PtU00QycWuIVf",
    },
    paypal: {
      monthly: "P-0HD41784BH837522DLYWPIYA",
      yearly: "P-3YB65722F8425070BLYWOECQ",
      clientId:
        "Aa2EYHOo5SyjAo6TI1BMc-XH-u7eHk_d6pB911628JHvaDROZSTIi-ca-8tRVIJVOnQid7z0D4g3xwdL",
    },
    studio: {
      url: "https://studio-stage.moises.ai",
    },
  },

  stage: {
    env: "stage",
    getAudioMix: {
      endpoint:
        "https://us-central1-moises-stage.cloudfunctions.net/getAudioMix/v1/run",
    },
    urlDownload: {
      endpoint: "https://url-download-stage.moises.ai",
    },
    api: {
      endpoint: "https://api-stage.moises.ai",
    },
    serviceBillingApi: {
      endpoint: "https://billing-stage.moises.ai",
    },
    firebase: {
      auth: {
        apiKey: "AIzaSyClKhEICctB11XUWd0GyO50Zu7aVWpwp-M",
        authDomain: "moises-stage.firebaseapp.com",
        databaseURL: "https://moises-stage.firebaseio.com",
        projectId: "moises-stage",
        storageBucket: "moises-stage.appspot.com",
        messagingSenderId: "941298360423",
        appId: "1:941298360423:web:eae58942fc585849a8f4c2",
      },
    },
    stripe: {
      apiKey: "pk_test_mRYBN1HrxY09g6KUL0jx9PtU00QycWuIVf",
    },
    paypal: {
      monthly: "P-0HD41784BH837522DLYWPIYA",
      yearly: "P-3YB65722F8425070BLYWOECQ",
      clientId:
        "Aa2EYHOo5SyjAo6TI1BMc-XH-u7eHk_d6pB911628JHvaDROZSTIi-ca-8tRVIJVOnQid7z0D4g3xwdL",
    },
    studio: {
      url: "https://studio-stage.moises.ai",
    },
  },

  production: {
    env: "production",
    getAudioMix: {
      endpoint:
        "https://us-central1-spleeter.cloudfunctions.net/getAudioMix/v1/run",
    },
    urlDownload: {
      endpoint: "https://url-download.moises.ai",
    },
    api: {
      endpoint: "https://api-next.moises.ai",
    },
    serviceBillingApi: {
      endpoint: "https://billing.moises.ai",
    },
    firebase: {
      auth: {
        apiKey: "AIzaSyDWcFRZcUnN5EPNNA7jrcuS3HlIvMqtuCs",
        authDomain: "app.moises.ai",
        databaseURL: "https://spleeter.firebaseio.com",
        projectId: "spleeter",
        storageBucket: "spleeter.appspot.com",
        messagingSenderId: "731360694588",
        appId: "1:731360694588:web:4da0f700a5193a834ace39",
        measurementId: "G-VCCHPVZC99",
      },
    },
    stripe: {
      apiKey: "pk_live_V8LD1ZGLLGFjoryvB60ftb5X00Dkg8tkQj",
    },
    paypal: {
      monthly: "P-1YG409718X2440516LYWMP2Q",
      yearly: "P-2LK43252R76700501LYWMQRA",
      clientId:
        "AUChJ6ogk_GxSl5advDUTX0OqAVQ6IcMeCJo3qcfmXODok1Pi-peOqCcejZwwP-d3aOlDzoU9diB1eV4",
    },
    studio: {
      url: "https://studio.moises.ai",
    },
  },
};

export const config = configs[env];
