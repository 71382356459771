import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return (
    <div className="footer-wrap">
    <div className="footer">
      <small>
        <Link to="/terms">Terms</Link>{" "}
        <span style={{ color: "#33374A" }}>|</span>{" "}
        <Link to="/privacy">Privacy</Link>{" "}
        <span style={{ color: "#33374A" }}>|</span>{" "}
        <Link to="/cookies">Cookie Policy</Link>{" "}
        <span style={{ color: "#33374A" }}>|</span>{" "}
        <a href="https://help.moises.ai">FAQ </a>
      </small>
    </div>
    </div>
  );
};
