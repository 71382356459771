import "./style.css";
import React from "react";
import { Layout } from "antd";
import Header from "./Header";
import Footer from "./Footer";
import HomeHeader from "../Home/HomeHeader";
const { Content } = Layout;

export default ({
  className,
  children,
  showHeader = true,
  showFooter = true,
}) => {
  return (
    <>
      <Layout className={className}>
        {showHeader ? null : <HomeHeader />}
        <Layout>
          <Content key={window.location.pathname}>{children}</Content>
        </Layout>
        {showFooter ? <Footer /> : null}
      </Layout>
    </>
  );
};
