import {createButton} from "react-social-login-buttons";
 
const config = {
  icon: 'twitter',
  iconFormat: name => `fa fa-${name} social-tw`,
  style: { background: "white", color:"black" },
  activeStyle: { background: "white", opacity:'0.9' }
};
const TwitterButton = createButton(config);
 
export default TwitterButton;