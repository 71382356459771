import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import Made from "./img/madewithmoises.svg"

function Privacy() {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://plugins.flockler.com/embed/178b73b6d45051a69c7ca81d67abb048/178b73f0c320ee665a4699ba8cbf0387";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    // <Layout showHeader={false} showFooter={false}>
      <div style={{ textAlign: "center", marginBottom: "30px", marginTop:"30px" }}>
        <img width={150} src={Made}/>
        <br/>
        <br/>
        <p><a href="https://instagram.com/moises.ai">Follow</a> us on Instagram for more. To be featured, use <strong>#MadeWithMoises</strong> hashtag</p>
        <div id="flockler-embed-178b73f0c320ee665a4699ba8cbf0387"></div>
      </div>
    // </Layout>
  );
}

export default Privacy;

